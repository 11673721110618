
  import { Component, Vue } from "vue-property-decorator";
  import { NFTForm, NFTImageCard, InputLabel } from "../components";

  @Component({ components: { NFTForm, NFTImageCard, InputLabel } })
  export default class Sell extends Vue {
    formValues = { price: "--", duration: "1 month" };
    putOnSale() {
      console.log("sold");
    }

    get nftImage() {
      return "https://uploads-ssl.webflow.com/61c0120d981c8f9d9322c0e0/61ca497efc91881256158064_blog%20article.png";
    }

    durations = [
      {
        label: this.$t("NFTs.sell.form.oneMonth"),
        value: "1 month"
      }
    ];
  }
